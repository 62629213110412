import type { DcChatbotLean } from "docuchatcommontypes";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TextRun,
} from "docx";
import type { DcChatMessage } from "~/types/chat";

export function getSupportedFileTypes() {
  const t = useNuxtApp().$i18n.t;

  const maxTotalSize = 50;
  const maxFileSizeForMedia = 25;

  const textName = t("user.documents.modals.add.file.types.text");
  const audioName = t("user.documents.modals.add.file.types.audio");
  const videoName = t("user.documents.modals.add.file.types.video");

  const typesInfo = [
    {
      name: textName,
      extensions: "PDF, DOCX, XLSX, PPTX, CSV, EPUB, TXT, MD",
      note: "",
    },
    {
      name: audioName,
      extensions: "MP3, WAV, M4A, MPGA",
      note: t("user.documents.modals.add.file.maxFilzeSize", { size: maxFileSizeForMedia }),
    },
    {
      name: videoName,
      extensions: "MP4, MPEG, WEBM",
      note: t("user.documents.modals.add.file.maxFilzeSize", { size: maxFileSizeForMedia }),
    },
  ];

  const textExtensions = typesInfo
    .find(t => t.name === textName)
    ?.extensions.split(", ")
    .map(e => `.${e.toLowerCase()}`) ?? [];

  const audioExtensions = typesInfo
    .find(t => t.name === audioName)
    ?.extensions.split(", ")
    .map(e => `.${e.toLowerCase()}`) ?? [];

  const videoExtensions = typesInfo
    .find(t => t.name === videoName)
    ?.extensions.split(", ")
    .map(e => `.${e.toLowerCase()}`) ?? [];

  const allExtensions = typesInfo
    .map(t => t.extensions.split(", "))
    .flat()
    .map(e => `.${e.toLowerCase()}`);

  return { typesInfo, allExtensions, audioExtensions, videoExtensions, textExtensions, maxTotalSize, maxFileSizeForMedia };
}

export function removeFileExtension(text: string) {
  return text.replace(/\.[^/.]+$/, "");
}

export function getFileExtension(text: string, withDot = false) {
  const t = useNuxtApp().$i18n.t;

  let extension = text.split(".").pop();

  if (!extension)
    throw createError(t("user.documents.modals.add.file.noExtensionFound"));

  extension = extension.toLowerCase();

  return withDot ? `.${extension}` : extension;
}

export function downloadAsCsvFile(fileNameWithoutExtension: string, data: any[]) {
  if (!data.length)
    return "";

  // Get the keys from the first object in the data array to use as headers
  const headers = Object.keys(data[0]).join(",");

  // Convert the data to CSV format
  const csvData = `${headers}\n${data.map(obj => Object.values(obj).join(",")).join("\n")}`;

  downloadStringAsFile(`${fileNameWithoutExtension}.csv`, csvData);
}

export function downloadStringAsFile(fileNameWithExtension: string, data: string) {
  const blob = new Blob([data], { type: "text/csv;charset=utf-8" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.download = fileNameWithExtension;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export function base64ToFile(filename: string, base64String: string, fileType?: string): void {
  let mimeType = "application/octet-stream";

  switch (fileType) {
    case "pdf":
      mimeType = "application/pdf";
      break;
    case "txt":
      mimeType = "text/plain";
      break;
    case "docx":
      mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      break;
    case "epub":
      mimeType = "application/epub+zip";
      break;
    case "pptx":
      mimeType = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      break;
    case "xlsx":
      mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      break;
    default:
      mimeType = "application/octet-stream";
  }

  const linkSource = `data:${mimeType};base64,${base64String}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
}

export function convertBytesToMB(bytes: number): number {
  const mb: number = bytes / 1048576;
  return Math.round(mb * 10) / 10; // Round to 1 decimal place
}

export async function exportChatToBlob(chatbot: DcChatbotLean, messages: DcChatMessage[]) {
  const doc = new Document({
    creator: "DocuChat",
    title: "Chat Session Export",
    sections: [
      {
        properties: {},
        children: [
          new Paragraph({
            text: "DocuChat Session Export",
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.CENTER,
            spacing: {
              after: 500,
            },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Date: ${new Date().toLocaleString()}`,
                bold: true,
              }),
            ],
            alignment: AlignmentType.RIGHT,
          }),
          ...messages.map(message => new Paragraph({
            children: [
              new TextRun({
                text: `${message.role === "assistant" ? chatbot.name : "User"} \n`,
                bold: true,
                size: 24,
              }),
              new TextRun({
                text: `${message.content} \n`,
                size: 22,
                break: 1,
              }),
            ],
            spacing: {
              after: 200,
            },
          })),
        ],
      },
    ],
    styles: {
      default: {
        document: {
          run: {
            font: "Arial",
            color: "111827",
          },
        },
      },
    },
  });

  const blob = await Packer.toBlob(doc);

  return blob;
}
